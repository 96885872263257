import * as React from 'react'
import { graphql, Link } from 'gatsby'
import Hero from '../components/Hero'
import Helmet from 'react-helmet'

export const query = graphql`
  query {
    hero: file(relativePath: { eq: "books_hero.jpg" }) {
      childImageSharp {
        fluid(maxWidth:1600, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }`

export default ({data}) => {

  return (
  <>
  <Hero title="Useful resources"
        subtitle="Protocol, policies and research to help manage surgical patients and
        protect healthcare professionals."
        fluid={data.hero.childImageSharp.fluid}
  />

  <section className="section" style={{margin:'2rem 0rem'}}>
    <div className="container">
      <div className="content is-thin">
      <p>PanSurg is collating a library of useful policy documents,
        protocols and research articles from around the world. We aim to provide a useful for resource for
        surgeons and other professionals managing surgical patients and their own workforce on the frontline.</p>
      </div>
      <hr className="is-divider"/>
    </div>
  </section>

  <section className="section">
    <div className="container">
      <div className="columns">
        <div className="column" style={{height:1000, overflowX : 'auto'}}>
          <div>
          <h2 className="subtitle has-text-weight-bold has-text-red">COVID Policies and Protocols (scroll down for more)</h2>

          <div className="box has-background-white-ter">
            <p className="subtitle has-text-weight-bold">Intercollegiate General Surgery Guidance on COVID-19 (25th March): </p>
            <a href="https://www.rcsed.ac.uk/news-public-affairs/news/2020/march/intercollegiate-general-surgery-guidance-on-covid-19#.XnuzEmXRHQw.twitter">
            Read here
            </a>
          </div>

          <div className="box has-background-white-ter">
            <p className="subtitle has-text-weight-bold">American College of Surgeons Triage Guidance (24th March 2020):</p>
            <a href="https://www.facs.org/covid-19/newsletter/032420/clinical-guidance">
            Read here
            </a>
          </div>

          <div className="box has-background-white-ter">
            <p className="subtitle has-text-weight-bold">Singapore: What we do when a COVID-19 patient needs an operation: operating room preparation and guidance (6th March 2020):</p>
            <a href="https://link.springer.com/article/10.1007%2Fs12630-020-01617-4">
            Read here
            </a>
          </div>

          <div className="box has-background-white-ter">
            <p className="subtitle has-text-weight-bold">Handbook of COVID-19 Preventation and Treatment</p>
            <p>The First Affiliated Hospital, Zhejiang University School of Medicine</p>
            <a href="https://video-intl.alicdn.com/Handbook%20of%20COVID-19%20Prevention%20and%20Treatment.pdf?spm=a3c0i.14138300.8102420620.download.5da1647fQF0tca&file=Handbook%20of%20COVID-19%20Prevention%20and%20Treatment.pdf">
            Read here
            </a>
          </div>

          <div className="box has-background-white-ter">
            <p className="subtitle has-text-weight-bold">NHS Clinical guide for the management of surgical patients during the coronavirus pandemic (16th March):</p>
            <a href="https://www.england.nhs.uk/coronavirus/wp-content/uploads/sites/52/2020/03/specialty-guide-surgery-and-coronavirus-v1-16-march-2020.pdf">
            Read here
            </a>
          </div>

          <div className="box has-background-white-ter">
            <p className="subtitle has-text-weight-bold">NHS Clinical guide for the management of cancer patients during the coronavirus pandemic (17th March):</p>
            <a href="https://www.england.nhs.uk/coronavirus/wp-content/uploads/sites/52/2020/03/Specialty-guide_Cancer-and-coronavirus_17-March.pdf">
            Read here
            </a>
          </div>

          <div className="box has-background-white-ter">
            <p className="subtitle has-text-weight-bold">SAGES Recommendations Regarding Surgical Response to COVID-19 Crisis</p>
            <a href="https://www.rcseng.ac.uk/-/media/files/rcs/coronavirus/joint-guidance--surgical-support-during-covid.pdf">
            Read here
            </a>
          </div>

          <div className="box has-background-white-ter">
            <p className="subtitle has-text-weight-bold">SAGES Basics of mechanical care ventilation for non-critical care clinicians</p>
            <a href="https://www.sages.org/basics-of-mechanical-ventilation-for-non-critical-care-mds/">
            Read here
            </a>
          </div>

          <div className="box has-background-white-ter">
            <p className="subtitle has-text-weight-bold">Managing COVID-19 in Surgical Systems</p>
            <p>Mary Brindley and Atul Gawande</p>
            <a href="https://journals.lww.com/annalsofsurgery/Documents/Managing%20COVID%20in%20Surgical%20Systems%20v2.pdf">
            Read here
            </a>
          </div>

          <div className="box has-background-white-ter">
            <p className="subtitle has-text-weight-bold">Zhejiang University School of Medicine Handbook of COVID-19 Prevention and Treatment (March 2020):</p>
            <a href="http://www.bapras.org.uk/docs/default-source/covid-19-docs/china-covid_19-advice.pdf?sfvrsn=2">
            Read here
            </a>
          </div>

          <div className="box has-background-white-ter">
            <p className="subtitle has-text-weight-bold">West China Hospital: Integrated infection control strategy to minimize nosocomial infection of coronavirus disease 2019 among ENT healthcare workers (27th February):</p>
            <a href="https://www.journalofhospitalinfection.com/article/S0195-6701(20)30092-X/fulltext">
            Read here
            </a>
          </div>

          </div>
        </div>
        <div className="column is-6">
          <h2 className="subtitle has-text-weight-bold has-text-red">COVID-19 Journal Articles</h2>

          <div className="box has-background-white-ter">
            <p className="subtitle has-text-weight-bold">The three vital lessons Italian hospitals have learned in fighting covid-19 (25th March):</p>
            <p>PanSurg Collaborative</p>
            <p className="is-italic">Health Services Journal</p>
            <a href="https://www.hsj.co.uk/7027220.article">
            Read here
            </a>
          </div>

          <div className="box has-background-white-ter">
            <p className="subtitle has-text-weight-bold">Practical recommendations for
            critical care and anesthesiology teams caring for novel coronavirus (2019-nCoV) patients</p>
            <p>Randy Wax and Michael Christian</p>
            <p className="is-italic">Canadian Journal of Anaesthesia</p>
            <a href="https://link.springer.com/content/pdf/10.1007/s12630-020-01591-x.pdf">
            Read here
            </a>
          </div>
          <h2 className="subtitle has-text-weight-bold has-text-red">Aerosol and COVID19</h2>
          <div className="box has-background-white-ter">
          <p className="subtitle has-text-weight-bold">Surgical smoke and infection control:</p>
            <a href="https://pubmed.ncbi.nlm.nih.gov/16002179/">
            Read here
            </a>
            </div>
            <div className="box has-background-white-ter">
          <p className="subtitle has-text-weight-bold">Aerosol and surface stability of HCoV-19 (SARS-CoV-2):</p>
            <a href="https://www.medrxiv.org/content/10.1101/2020.03.09.20033217v2.full.pdf">
            Read here
            </a>
            </div>
            <div className="box has-background-white-ter">
          <p className="subtitle has-text-weight-bold">Evidence for gastrointestinal infection of SARS-CoV-2:</p>
            <a href="https://www.gastrojournal.org/article/S0016-5085(20)30282-1/pdf">
            Read here
            </a>
            </div>

          <h2 className="subtitle has-text-weight-bold has-text-red">Media</h2>
          <div className="box has-background-white-ter">

            <p className="subtitle has-text-weight-bold">Sterile PPE donning and Doffing</p>
            <iframe src="https://www.youtube.com/embed/1M9lWuLzV28" width="500" height="250"></iframe>


            </div>

        </div>
      </div>
    </div>
  </section>

  </>
  )
}
